<template>
  <div class="animated fadeIn">
    <CardTable :title="title"
               :columns="table.columns"
               :rows="table.rows"
               :isLoading="table.isLoading"
               :pageSize="serverParams.pageSize"
               :pageNumber="serverParams.pageNumber"
               :totalPage="table.totalPage"
               :totalRecords="table.totalRecords"
               @onRowSelected="onRowSelected"
               @onPageChange="onPageChange">
      <template #action>
        <div class="card-header-actions">
          <b-button :to="{ name: 'InvoiceAdjustment' }"
                    size="sm"
                    variant="primary">
            Create New
          </b-button>
        </div>
      </template>
      <template #filter>
        <SectionFilter :serverParams="serverParams" @onChange="getAll" />
      </template>
      <template #cell(isApplied)="data">
        <span v-show="data.item.isApplied" class="text-success">Applied</span>
        <span v-show="!data.item.isApplied" class="text-danger">Not Applied</span>
      </template>
    </CardTable>
  </div>
</template>

<script>
  import { numberFormat } from "@/shared/utils";
  import { adjustments as columns } from "@/shared/columns";
  import SectionFilter from "./SectionFilter.vue";

  export default {
    components: {
      SectionFilter,
    },
    data: () => ({
      title: "Adjustments",
      serverParams: {
        pageNumber: 1,
        pageSize: 100,
        keyword: null,
        //name: null,
        //uen: null,
        //email: null,
        //address: null,
        //postalCode: null,
        //deleted: false,
        //orderBy: "accountId"
      },
      table: {
        isLoading: true,
        columns,
        rows: [],
        totalPage: 0,
        totalRecords: 0,
      },
    }),
    created() {
      const self = this;
      self.getAll();
    },
    methods: {
      numberFormat,
      updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
      },
      applyAdjustment() {
        const self = this;
        let _confirmText = "You are about to apply the adjustments for the invoices'. Are you sure ?",
          _okText = "Yes, Submit",
          _action = "apis/post",
          _url = `/transaction/invoice/apply-adjustment`;
        self.$dialog
          .confirm(_confirmText, {
            okText: _okText,
            cancelText: "Cancel",
            loader: true,
          }).then((dialog) => {
            self.$store
              .dispatch(_action, {
                url: _url,
              })
              .then((response) => {
                dialog.close();
                if (response.error) {
                  self.$message.error({
                    zIndex: 10000,
                    message: response.message,
                  });
                } else {
                  self.$message.success({
                    zIndex: 10000,
                    message: response.message,
                  });
                  this.getAll();
                }
              });
          });
      },
      onRowSelected(items) {
        const self = this;
        self.$router.push({
          name: "InvoiceAdjustmentUpdate",
          params: {
            id: items[0].adjustmentId,
          },
        });
      },
      onPageChange(params) {
        this.updateParams({ pageNumber: params });
        this.getAll();
      },
      getAll() {
        const self = this;

        self.table.isLoading = true;
        self.$store
          .dispatch("apis/get", {
            url: "/transaction/adjustment-history",
            params: self.serverParams,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              self.table.rows = response.data.data;
              self.table.totalPage = response.data.totalPage;
              self.table.totalRecords = response.data.totalData;
            }
            self.table.isLoading = false;
          });
      },
    },
  };
</script>
