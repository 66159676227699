<template>
  <div>
    <div class="px-3 py-2">
      <b-row class="align-items-center">
        <b-col sm="4">
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text>
                <i class="icon-magnifier"></i>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input type="text"
                          class="form-control"
                          placeholder="Search by account#"
                          autocomplete="off"
                          v-model="serverParams.keyword"
                          @input="onChange" />
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col sm="4">
          <form-group label="Period" :use-horizontal="false">
            <b-form-input type="month"
                          placeholder="Period"
                          autocomplete="off"
                          v-model="serverParams.period"
                          @input="onChange" />
          </form-group>
        </b-col>
        <b-col sm="4">
          <form-group label="‎‎ " :use-horizontal="false">
            <BuildingDropdown :server-params="serverParams"
                              :on-select="onChange"/>
          </form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import BuildingDropdown from "@/components/common/BuildingDropdown";
  export default {
    props: {
      serverParams: Object,
    },
    data: () => ({
      addFilter: false,
    }),
    components: {
      BuildingDropdown,
    },
    methods: {
      onChange() {
        this.serverParams.pageNumber = 1;
        this.$emit("onChange", this.serverParams);
      },
    },
  };
</script>
